import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const Game = () => {
    let { gameId } = useParams();
    const [game, setGame] = useState(null)
    useEffect(() => {
        getGame(gameId);
        const timer = setInterval(() => {
            getGame(gameId);
        }, 7000)
        return () => clearInterval(timer);
    }, [gameId])

    const getGame = (gameId) => {
        fetch(`https://api.bulldogproject.eu/api/games/${gameId}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'x-auth-token': localStorage.getItem('token') },
        }).then(response => {
            response.json().then(json => {
                if (response.ok) {
                    console.log(json)
                    setGame(json.game)
                } else {
                    console.log(json.errors)
                }

            })
        })
            .catch(error => {
                return error;
            });
    }
    // useEffect(() => {
    //     const socket = socketIOClient(`http://localhost:5000/`,
    //         {
    //             // transports: ['websocket'],
    //             //     agent: false,
    //             //     upgrade: false,
    //             //     rejectUnauthorized: false
    //         }
    //     );
    //     socket.on('change', (mess) => {
    //         console.log(mess)
    //     })
    //     socket.on('newplayer', (data) => {
    //         console.log(data)
    //         setGame(data);
    //     });
    // }, [])


    return (
        <>
            <div className="container mx-auto px-6 py-8 mb-6">
                <h3 className="text-gray-700 text-3xl font-medium">Game: {game ? game.name : null}</h3>
                <h3 className="text-gray-700 text-3xl font-medium">Game ID: {game ? game.gameId : null}</h3>
                <h3 className="text-gray-700 text-3xl font-medium">Game Date: {game ? game.date.substring(0, 10) : null}</h3>

            </div>
            <table className="min-w-full">
                <thead>
                    <tr>
                        <th className="px-6 py-3 border-b text-left text-md bg-purple-700 leading-4 font-large text-gray-100 uppercase tracking-wider"></th>
                        <th className="px-6 py-3 border-b text-left text-md bg-purple-700 leading-4 font-large text-gray-100 uppercase tracking-wider"></th>
                        <th  className="px-6 py-3 border-l border-b text-left text-md bg-purple-700 leading-4 font-large text-gray-100 uppercase tracking-wider">PART 1</th>
                        <th className="px-6 py-3 border-b text-left text-md bg-purple-700 leading-4 font-large text-gray-100 uppercase tracking-wider"></th>
                        <th className="px-6 py-3 border-b text-left text-md bg-purple-700 leading-4 font-large text-gray-100 uppercase tracking-wider"></th>
                        <th className="px-6 py-3 border-b text-left text-md bg-purple-700 leading-4 font-large text-gray-100 uppercase tracking-wider"></th>
                        <th className="px-6 py-3 border-b text-left text-md bg-purple-700 leading-4 font-large text-gray-100 uppercase tracking-wider"></th>
                        <th className="px-6 py-3 border-l border-b text-left text-md bg-purple-700 leading-4 font-large text-gray-100 uppercase tracking-wider">PART 3</th>
                    </tr>
                    <tr>
                        <th
                            className="px-6 py-3 border-b border-gray-200 bg-purple-700 text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider">
                            A/A</th>
                        <th
                            className="px-6 py-3 border-b border-gray-200 bg-purple-700 text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider">
                            Players</th>
                        <th
                            className="px-6 py-3 border-b border-l border-gray-200 bg-purple-700 text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider">
                            Physical Aggression</th>
                        <th
                            className="px-6 py-3 border-b border-gray-200 bg-purple-700 text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider">
                            Verbal Aggression</th>
                        <th
                            className="px-6 py-3 border-b border-gray-200 bg-purple-700 text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider">
                            Anger</th>
                        <th
                            className="px-6 py-3 border-b border-gray-200 bg-purple-700 text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider">
                            Hostility</th>
                            <th
                            className="px-6 py-3 border-b border-gray-200 bg-purple-700 text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider">
                            Total</th>
                            <th
                            className="px-6 py-3 border-b border-l border-gray-200 bg-purple-700 text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider">
                            Score</th>
                    </tr>
                </thead>

                <tbody className="bg-white">
                    {game ? game.players.map((player, i) =>
                        <tr key={i} className="hover:bg-gray-200 cursor-pointer text-gray-900 ">
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                <div className="flex items-center">
                                    <div className="text-sm leading-5 font-medium ">
                                        {i + 1}
                                    </div>
                                </div>
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                <div className="flex items-center">
                                    <div className="text-sm leading-5 font-medium">
                                        {player.user_name}
                                    </div>
                                </div>
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-l border-gray-200">
                                <div className="flex items-center">
                                    <div className="text-sm leading-5 font-medium">
                                        {player.part_1 ? player.part_1.PA_score : "--"}
                                    </div>
                                </div>
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                <div className="flex items-center">
                                    <div className="text-sm leading-5 font-medium">
                                        {player.part_1 ? player.part_1.VA_score : "--"}
                                    </div>
                                </div>
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                <div className="flex items-center">
                                    <div className="text-sm leading-5 font-medium">
                                        {player.part_1 ? player.part_1.A_score : "--"}
                                    </div>
                                </div>
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                <div className="flex items-center">
                                    <div className="text-sm leading-5 font-medium">
                                        {player.part_1 ? player.part_1.H_score : "--"}
                                    </div>
                                </div>
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                <div className="flex items-center">
                                    <div className="text-sm leading-5 font-medium">
                                        {player.part_1 ? player.part_1.H_score +  player.part_1.A_score + player.part_1.VA_score + player.part_1.PA_score: "--"}
                                    </div>
                                </div>
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-l border-gray-200">
                                <div className="flex items-center">
                                    <div className="text-sm leading-5 font-medium">
                                        {player.part_3 && player.part_3.score ? player.part_3.score+"/10" : "--"}
                                    </div>
                                </div>
                            </td>
                        </tr>)
                        : null}
                </tbody>
            </table>
        </>
    )
}

export default Game;