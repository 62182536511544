import React, { useRef, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import Logo from '../assets/images/logo.svg';

const NewPassword = (props) => {
  const { userId, token } = useParams();
  const password = useRef(null);
  const retypepassword = useRef(null);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);


  const resetPassword = (e) => {
    setError(null);
    setMessage(null);
    e.preventDefault();
    if (password.current.value !== retypepassword.current.value) {
      setError('Your password does not match')
    } else {
      fetch(`https://api.bulldogproject.eu/api/users/reset/${userId}/${token}`, {
        method: 'POST',
        body: JSON.stringify({ password: password.current.value }),
        headers: { 'Content-Type': 'application/json' },
      }).then(response => {
        response.json().then(json => {
          if (response.ok) {
            setMessage("You have successfully changed your password")
          } else {
            console.log(json)
            setError(json.errors[0].msg);
          }
        })
      })
        .catch(error => {
          console.log(error);
        });

    }
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full">
        <div>
          <Link to="/"><img className="mx-auto h-12 w-auto" src={Logo} alt="logo" /></Link>
          <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
            Reset Your password
            </h2>
        </div>
        <form className="mt-8" action="/" onSubmit={(e) => resetPassword(e)}>
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm">
            <div className="">
              <input aria-label="Password" name="password" type="password" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-purple focus:border-purple-600 focus:z-10 sm:text-sm sm:leading-5" placeholder="Password" ref={password} />
            </div>
            <div className="">
              <input aria-label="Confirm your password" name="retype-password" type="password" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-purple focus:border-purple-600 focus:z-10 sm:text-sm sm:leading-5" placeholder="Confirm your password" ref={retypepassword} />
            </div>
          </div>

          {/* <div className="mt-6 flex items-center justify-between">
            <div className="text-sm leading-5">
              {message ? message : null}
            </div>
          </div> */}
          {message ?
            <div class="absolute top-0 mt-4 inline-flex max-w-sm w-full bg-white shadow-md rounded-lg overflow-hidden ml-3">
              <div class="flex justify-center items-center w-12 bg-green-500">
                <svg class="h-6 w-6 fill-current text-white" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z"></path>
                </svg>
              </div>

              <div class="-mx-3 py-2 px-4">
                <div class="mx-3">
                  <span class="text-green-500 font-semibold">Success</span>
                  <p class="text-gray-600 text-sm">{message}</p>
                </div>
              </div>
            </div>
            : null}
          {error ?
            <div className="absolute top-0 mt-4 inline-flex max-w-sm w-full bg-white shadow-md rounded-lg overflow-hidden ml-3">
              <div class="flex justify-center items-center w-12 bg-red-500">
                <svg class="h-6 w-6 fill-current text-white" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z"></path>
                </svg>
              </div>
              <div class="-mx-3 py-2 px-4">
                <div class="mx-3">
                  <span class="text-red-500 font-semibold">Error</span>
                  <p class="text-gray-600 text-sm">{error}</p>
                </div>
              </div>
            </div> : null}

          <div className="mt-6">
            <button type="submit" style={{ background: '#583687' }} className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white  focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out" >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <svg className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400 transition ease-in-out duration-150" fill="#fff" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                </svg>
              </span>
                Reset
              </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default NewPassword;