import React, { useState, useEffect, useRef } from "react";
import { useHistory, Switch, Route, Link } from "react-router-dom";
import Games from "../Dashboard/Games";
import Profile from "../Dashboard/Profile";
import NewGame from "../Dashboard/New_game";
import Game from "../Dashboard/Game";
import Logo from "../assets/images/logo.svg";

const Dashboard = () => {
  const history = useHistory();
  const [showProfile, setshowProfile] = useState(false);
  const [profile, setProfile] = useState({});
  const profileRef = useRef(null);
  const profileMenu = useRef(null);
  const dashClick = useRef(null);
  const newgameClick = useRef(null);
  const logoutAction = useRef(null);
  const [showSidebar, setShowSidebar] = useState(false);
  useEffect(() => {
    getProfile();
  }, []);
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const getProfile = async () => {
    try {
      const response = await fetch(
        `https://api.bulldogproject.eu/api/auth`,
        {
          method: "GET",
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      );
      const json = await response.json();
      response.ok ? setProfile(json) : console.log(response.statusText);
    } catch (error) {
      console.log(error);
    }
  };
  const logOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("token");
    history.push("/login");
  };
  const showProfileMenu = () => {
    showProfile ? setshowProfile(false) : setshowProfile(true);
  };
  const handleClickOutside = (e) => {
    if (
      profileRef &&
      !profileRef.current.contains(e.target) &&
      profileMenu &&
      !profileMenu.current.contains(e.target)
    ) {
      setshowProfile(false);
    }

    if (
      (dashClick && dashClick.current.contains(e.target)) ||
      (newgameClick && newgameClick.current.contains(e.target))
    ) {
      setShowSidebar(false);
    }
  };

  const handleSidebar = (e) => {
    setShowSidebar(true);
  };
  return (
    <div className="flex h-screen bg-gray-200">
      <div
        className={`translate-x-0 ease-out fixed z-30 inset-y-0 left-0 w-64 transition duration-300 transform bg-gray-900 overflow-y-auto lg:translate-x-0 md:static lg:inset-0 ${
          !showSidebar ? "hidden" : ""
        } md:block `}
      >
        <div className="flex items-center justify-center mt-8">
          <div className="flex items-center">
            {/* <svg className="h-12 w-12" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M364.61 390.213C304.625 450.196 207.37 450.196 147.386 390.213C117.394 360.22 102.398 320.911 102.398 281.6C102.398 242.291 117.394 202.981 147.386 172.989C147.386 230.4 153.6 281.6 230.4 307.2C230.4 256 256 102.4 294.4 76.7999C320 128 334.618 142.997 364.608 172.989C394.601 202.981 409.597 242.291 409.597 281.6C409.597 320.911 394.601 360.22 364.61 390.213Z"
                                fill="#583687" stroke="#583687" strokeWidth="2" strokeLinecap="round"
                                strokeLinejoin="round"></path>
                            <path
                                d="M201.694 387.105C231.686 417.098 280.312 417.098 310.305 387.105C325.301 372.109 332.8 352.456 332.8 332.8C332.8 313.144 325.301 293.491 310.305 278.495C295.309 263.498 288 256 275.2 230.4C256 243.2 243.201 320 243.201 345.6C201.694 345.6 179.2 332.8 179.2 332.8C179.2 352.456 186.698 372.109 201.694 387.105Z"
                                fill="white"></path>
                        </svg> */}
            <img className="mx-auto h-12 w-auto" src={Logo} alt="logo" />
            {/* <span className="text-white text-2xl mx-2 font-semibold">Dashboard</span> */}
          </div>
        </div>
        <nav className="mt-10">
          <Link
            to="/"
            className="flex items-center mt-4 py-2 px-6 bg-gray-700 bg-opacity-25 text-gray-100"
            ref={dashClick}
          >
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
              ></path>
            </svg>

            <span className="mx-3">Dashboard</span>
          </Link>
          <Link
            to="/new_game"
            className="flex items-center mt-4 py-2 px-6 bg-gray-700 bg-opacity-25 text-gray-100"
            ref={newgameClick}
          >
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"
              ></path>
            </svg>

            <span className="mx-3">New Game</span>
          </Link>
        </nav>
      </div>
      <div className="flex-1 flex flex-col overflow-hidden">
        <header className="flex justify-between items-center py-4 px-6 bg-white border-b-4 border-purple-600">
          <div className="flex items-center">
            <button
              className="text-gray-500 focus:outline-none lg:hidden"
              onClick={handleSidebar}
            >
              <svg
                className="h-6 w-6"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 6H20M4 12H20M4 18H11"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </button>

            {/* <div className="relative mx-4 lg:mx-0">
                            <span className="absolute inset-y-0 left-0 pl-3 flex items-center">
                                <svg className="h-5 w-5 text-gray-500" viewBox="0 0 24 24" fill="none">
                                    <path
                                        d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                                        stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                        strokeLinejoin="round">
                                    </path>
                                </svg>
                            </span>

                            <input className="form-input w-32 sm:w-64 rounded-md pl-10 pr-4 focus:border-indigo-600" type="text"
                                placeholder="Search" />
                        </div> */}
          </div>
          <div className="flex items-center">
            <div className="relative">
              <button
                className="relative block h-8 w-8 rounded-full overflow-hidden shadow focus:outline-none"
                onClick={showProfileMenu}
                ref={profileRef}
              >
                <img
                  className="h-full w-full object-cover"
                  src={profile.avatar}
                  alt="Your avatar"
                />
              </button>

              <div
                className="fixed inset-0 h-full w-full z-10"
                style={{ display: "none" }}
              ></div>

              <div
                className="absolute right-0 mt-2 w-48 bg-white rounded-md overflow-hidden shadow-xl z-10"
                style={showProfile ? { display: "block" } : { display: "none" }}
                ref={profileMenu}
              >
                {/* <a href="/profile"
                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-600 hover:text-white">Profile</a> */}
                <Link
                  to="/profile"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-purple-700 hover:text-white"
                >
                  Profile
                </Link>
                <Link
                  to="/"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-purple-700 hover:text-white"
                  onClick={(e) => logOut(e)}
                  ref={logoutAction}
                >
                  Logout
                </Link>
              </div>
            </div>
          </div>
        </header>
        <main className="flex-1 md:overflow-x-hidden overflow-y-auto bg-gray-200">
          <Switch>
            <Route exact path="/">
              <Games />
            </Route>
            <Route path="/profile">
              <Profile profile={profile} />
            </Route>
            <Route path="/new_game">
              <NewGame />
            </Route>
            <Route path="/game/:gameId">
              <Game />
            </Route>
          </Switch>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
