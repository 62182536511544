import React from 'react';
import ReactDOM from 'react-dom';
import './tailwind.generated.css'
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import ProtectedRoute from './ProtectedRoute';
import Register from './components/Register';
import NewPassword from './components/NewPassword.js'
import Reset from './components/Reset.js'
import * as serviceWorker from './serviceWorker';

ReactDOM.render((
  <BrowserRouter>
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/register" component={Register} />
      <Route path="/reset/" component={Reset} />
      <Route path="/password/reset/:userId/:token" component={NewPassword} />
      <ProtectedRoute exact={true} path="/" component={Dashboard} />
      <ProtectedRoute component={Dashboard} />
    </Switch>
  </BrowserRouter>
), document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
